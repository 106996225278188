import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
	selector: 'sl-dialog',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './dialog.component.html',
	styleUrl: './dialog.component.scss',
	standalone: true,
	imports: [
		MatIconModule,
		MatButtonModule,
		MatDialogModule
	]
})
export class DialogComponent {
	readonly titleSignal$ = input<string>(null, { alias: 'title' });
	readonly styleClassSignal$ = input<string>(null, { alias: 'styleClass' });
	readonly customCloseSignal$ = input<boolean>(false, { alias: 'customClose' });
	readonly justifyActionsToStartSignal$ = input<boolean>(false, { alias: 'justifyActionsToStart' });

	readonly closeClicked = output<any>();

	onCloseClick(): void {
		this.closeClicked.emit(null);
	}
}
