import { Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { GenericDialogData } from '../../models/dialog.model';

@Component({
	selector: 'sl-generic-dialog',
	templateUrl: './generic-dialog.component.html',
	styleUrl: './generic-dialog.component.scss',
	standalone: true,
	imports: [
		MatButtonModule,
		MatDialogModule
	]
})
export class GenericDialogComponent implements OnInit {
	title: SafeHtml;
	content: SafeHtml;

	readonly data = inject(MAT_DIALOG_DATA) as GenericDialogData;
	readonly #sanitizer = inject(DomSanitizer);

	ngOnInit(): void {
		this.title = this.#sanitizer.bypassSecurityTrustHtml(this.data?.title);
		this.content = this.#sanitizer.bypassSecurityTrustHtml(this.data?.content);
	}
}
