import { Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { ButtonModule } from 'primeng/button';

import { ConfirmationDialogData } from '../../models/confirmation-dialog.model';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
	selector: 'sl-confirmation-dialog-new',
	templateUrl: './confirmation-dialog-new.component.html',
	styleUrl: './confirmation-dialog-new.component.scss',
	standalone: true,
	imports: [
		DialogComponent,
		MatButtonModule,
		MatDialogModule,
		ButtonModule
	]
})
export class ConfirmationDialogNewComponent implements OnInit {
	content = 'Are you sure?';
	cancelButtonCaption = 'No';
	confirmButtonCaption = 'Yes';
	defaultDialogData: ConfirmationDialogData = {
		title: '',
		content: 'Are you sure?',
		cancelButtonCaption: 'No',
		confirmButtonCaption: 'Yes',
		showCancelButton: true
	};

	mergedData: ConfirmationDialogData;

	readonly data = inject(MAT_DIALOG_DATA) as ConfirmationDialogData;

	ngOnInit(): void {
		this.mergedData = { ...this.defaultDialogData, ...this.data };
	}
}
