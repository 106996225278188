import { DecimalPipe } from '@angular/common';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ButtonModule } from 'primeng/button';

import { UploadDialogModel } from '../../models/upload-dialog.model';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
	selector: 'sl-upload-dialog',
	templateUrl: './upload-dialog.component.html',
	styleUrl: './upload-dialog.component.scss',
	standalone: true,
	imports: [
		DecimalPipe,
		ButtonModule,
		DialogComponent,
		MatButtonModule,
		MatDialogModule,
		MatIconModule,
		MatProgressBarModule,
	]
})
export class UploadDialogComponent implements OnInit {
	error: string;
	errorMessage: string;
	isLoading: boolean;
	uploadFinished = false;
	uploadSuccessMessage: string;
	readonly config = inject(MAT_DIALOG_DATA) as UploadDialogModel;
	readonly #destroyRef = inject(DestroyRef);

	ngOnInit(): void {
		this.config?.onError?.pipe(
			takeUntilDestroyed(this.#destroyRef)
		).subscribe(errorObj => {
			this.error = errorObj.title;
			this.errorMessage = errorObj.message;
			this.isLoading = false;
		});

		this.config?.onUploadSuccess?.pipe(
			takeUntilDestroyed(this.#destroyRef)
		).subscribe(message => {
			this.isLoading = false;
			this.uploadSuccessMessage = message;
			this.uploadFinished = true;
		});
	}

	onConfirm(): void {
		this.isLoading = true;
		this.config?.onSave?.emit();
	}

	onCancel(): void {
		this.config?.onCancel?.emit();
	}

	onView(): void {
		this.config?.onAction?.emit('view');
	}
}
