@if (!error) {
	<sl-dialog
		class="upload-modal"
		[title]="config?.title">
		<ng-container content>
			<div class="upload-modal__content">
				<mat-icon
					class="file-icon"
					svgIcon="csv"></mat-icon>
				<div class="sl-flexbox sl-flexbox-column sl-flexbox-basis-100 sl-flexbox-gap-5">
					<div class="sl-flexbox sl-flexbox-align-center sl-typography-body-medium">
						<div class="sl-flexbox-basis-100">
							{{ config?.file?.name }} | {{ config?.file?.size / 1024 | number: "1.1-1" }}KB
						</div>
						@if (!uploadFinished) {
							<button
								pButton
								type="button"
								class="p-button-link"
								(click)="onCancel()">
								Cancel
							</button>
						} @else {
							<button
								pButton
								type="button"
								class="p-button-link"
								(click)="onView()">
								View
							</button>
						}
					</div>
					@if (!uploadFinished) {
						<mat-progress-bar mode="indeterminate"></mat-progress-bar>
					} @else {
						<mat-progress-bar mode="determinate" value="100"></mat-progress-bar>
					}
				</div>
			</div>
			@if (uploadFinished) {
				<div class="description sl-typography-body-medium">
					File uploaded successfully.<br />
					{{ uploadSuccessMessage }}
				</div>
			}
		</ng-container>
		<ng-container actions>
			@if (uploadFinished) {
				<button
					mat-button
					class="new-design"
					[mat-dialog-close]="true"
					[disabled]="isLoading">
					Close
				</button>
			}
		</ng-container>
	</sl-dialog>
} @else {
	<sl-dialog
		class="upload-modal"
		[title]="error">
		<ng-container content>
			<div
				class="upload-modal__error sl-typography-body-medium"
				[innerHtml]="errorMessage"></div>
		</ng-container>
		<ng-container actions>
			<button
				mat-button
				class="new-design"
				type="button"
				[mat-dialog-close]="true"
				[disabled]="isLoading">
				Got it
			</button>
		</ng-container>
	</sl-dialog>
}
