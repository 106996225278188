<sl-dialog [title]="mergedData.title" [styleClass]="mergedData.titleStyleClass">
	<ng-container content>
		<div class="sl-flexbox-gap-16 sl-typography-body {{ mergedData.contentStyleClass }}" [innerHTML]="mergedData.content"></div>
	</ng-container>
	<ng-container actions>
		@if (mergedData.showCancelButton && mergedData.cancelButtonCaption?.length) {
			<p-button
				[mat-dialog-close]="false"
				styleClass="sl-v1-button"
				severity="naked"
				size="large">
				{{ mergedData.cancelButtonCaption }}
			</p-button>
		}
		<p-button
			styleClass="sl-v1-button"
			size="large"
			[mat-dialog-close]="true">
			{{ mergedData.confirmButtonCaption }}
		</p-button>
	</ng-container>
</sl-dialog>
