<div mat-dialog-title class="confirmation-dialog-headersl-flexbox-row sl-flexbox-justify-space-between sl-flexbox-align-center">
	@switch(mergedData?.type) {
		@case ('success') {
			<mat-icon class="success">check_circle</mat-icon>
		}
		@case ('info') {
			<mat-icon class="info">info</mat-icon>
		}
		@case ('warning') {
			<mat-icon class="warning">warning</mat-icon>
		}
		@case ('error') {
			<mat-icon class="error">error</mat-icon>
		}
	}
	<span class="dialog-title">{{ mergedData.title }}</span>
</div>
<div mat-dialog-content class="confirmation-dialog-content">
	<div class="content-wrapper">
		<div class="message" [innerHtml]="mergedData.content"></div>
	</div>
</div>
<div mat-dialog-actions class="confirmation-dialog-actions sl-flexbox-row sl-flexbox-justify-end sl-flexbox-align-center">
	@if (mergedData.showCancelButton) {
			<p-button styleClass="sl-v1-button" size="large" type="button" severity="naked" (onClick)="cancel()">{{ mergedData.cancelButtonCaption }}</p-button>
	}
	<p-button type="button" styleClass="sl-v1-button" size="large" (onClick)="confirm()">
		@if (mergedData.confirmButtonIcon) {
			<mat-icon class="button-icon">{{ mergedData.confirmButtonIcon }}</mat-icon>
		}
		{{ mergedData.confirmButtonCaption }}
	</p-button>
</div>
